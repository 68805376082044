import Dashboard from '../components/Dashboard/Dashboard';
import { ClicksProvider } from '../context/ClicksContext';
import { OptionsProvider } from '../context/OptionsContext';

function CreatePage() {
   return (
      <OptionsProvider>
         <ClicksProvider>
            <Dashboard />
         </ClicksProvider>
      </OptionsProvider>
   );
}
export default CreatePage;
