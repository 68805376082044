import { Button, ListSubheader } from '@mui/material';
import {
   useGetClicks,
   useGetClicksDispatch,
} from '../../../context/ClicksContext';
import { ClickPatternDetails } from './ClickPatternsDetails';

const ClickPatterns = () => {
   const { clicks, savedClickPatterns } = useGetClicks();
   const dispatch = useGetClicksDispatch();

   const handleSaveClicks = () => {
      dispatch({
         type: 'save-clicks',
         clicks,
      });
   };

   return (
      <>
         <ListSubheader
            component="div"
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
            }}
         >
            Click Patterns
         </ListSubheader>
         <ListSubheader
            component="div"
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
            }}
         >
            <Button
               variant="contained"
               color="primary"
               onClick={handleSaveClicks}
               disabled={clicks.length === 0}
            >
               Save Clicks - {clicks.length}
            </Button>
         </ListSubheader>
         <ClickPatternDetails patterns={savedClickPatterns} />
      </>
   );
};
export default ClickPatterns;
