import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {
   Badge,
   Box,
   Container,
   CssBaseline,
   Divider,
   IconButton,
   List,
   Toolbar,
   Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import React from 'react';
import {
   mainDashboardMenuItems,
   secondaryDashboardMenuItems,
} from './DashboardMenuItems';
import GeometricGrid from '../GeometricGrid';

// todo refactor this component into multiple components

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
   open?: boolean;
}

const AppBar = styled(MuiAppBar, {
   shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
   zIndex: theme.zIndex.drawer + 1,
   transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
   }),
   ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
   }),
}));

const Drawer = styled(MuiDrawer, {
   shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
   '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
         overflowX: 'hidden',
         transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
         }),
         width: theme.spacing(7),
         [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
         },
      }),
   },
}));

function Dashboard() {
   const [open, setOpen] = React.useState(false);

   const toggleDrawer = () => {
      setOpen(!open);
   };

   // todo move this to a service
   const offsetBottomValueGeoGrid = '64px';

   return (
      <Box sx={{ display: 'flex' }}>
         <CssBaseline />
         <AppBar position="absolute" open={open}>
            <Toolbar
               sx={{
                  pr: '24px', // keep right padding when drawer closed
               }}
            >
               <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  sx={{
                     marginRight: '36px',
                     ...(open && {
                        display: 'none',
                     }),
                  }}
               >
                  <MenuIcon />
               </IconButton>
               <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
               >
                  Geometry Generator
               </Typography>
               <IconButton color="inherit">
                  <Badge badgeContent={4} color="secondary">
                     <NotificationsIcon />
                  </Badge>
               </IconButton>
            </Toolbar>
         </AppBar>
         <Drawer variant="permanent" open={open}>
            <Toolbar
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  px: [1],
               }}
            >
               <IconButton onClick={toggleDrawer}>
                  <ChevronLeftIcon />
               </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
               {mainDashboardMenuItems}
               <Divider sx={{ my: 1 }} />
               {secondaryDashboardMenuItems}
            </List>
         </Drawer>
         <Box
            component="main"
            sx={{
               backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                     ? theme.palette.grey[100]
                     : theme.palette.grey[900],
               flexGrow: 1,
               height: '100vh',
               overflow: 'auto',
            }}
         >
            <Toolbar />
            <Container maxWidth={false} style={{ padding: 0 }}>
               <div
                  style={{
                     border: '1px solid black',
                     height: `calc(100vh - ${offsetBottomValueGeoGrid})`,
                  }}
               >
                  <GeometricGrid open={open} />
               </div>
            </Container>
         </Box>
      </Box>
   );
}
export default Dashboard;
