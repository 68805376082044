import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import WavesIcon from '@mui/icons-material/Waves';

export const WaveOptions = () => {
   return (
      <ListItemButton>
         <ListItemIcon>
            <WavesIcon />
         </ListItemIcon>
         <ListItemText primary="Wave" />
      </ListItemButton>
   );
};
