import {
   FormControl,
   InputLabel,
   ListItemButton,
   ListItemIcon,
   MenuItem,
   Select,
   SelectChangeEvent,
} from '@mui/material';
import InterestsIcon from '@mui/icons-material/Interests';
import {
   useGetOptions,
   useGetOptionsDispatch,
} from '../../context/OptionsContext';
import { getShapes } from '../../services/shapes/ShapeService';
import { Shapes } from '../../types/baseEnums';

function ShapesOptions() {
   const { shape } = useGetOptions();
   const dispatch = useGetOptionsDispatch();

   const handleShapeChange = (e: SelectChangeEvent) => {
      dispatch({
         type: 'update-shape',
         shape: e.target.value as Shapes,
      });
   };

   return (
      <ListItemButton color="secondary">
         <ListItemIcon>
            <InterestsIcon />
         </ListItemIcon>
         <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Shapes</InputLabel>
            <Select
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={shape}
               label="Age"
               onChange={handleShapeChange}
            >
               {getShapes().map((shape: string) => {
                  return (
                     <MenuItem key={shape} value={shape}>
                        {shape}
                     </MenuItem>
                  );
               })}
            </Select>
         </FormControl>
      </ListItemButton>
   );
}
export default ShapesOptions;
