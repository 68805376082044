import {
   FormControl,
   InputLabel,
   ListItemButton,
   ListItemIcon,
   MenuItem,
   Select,
   SelectChangeEvent,
} from '@mui/material';
import StairsIcon from '@mui/icons-material/Stairs';
import {
   useGetOptions,
   useGetOptionsDispatch,
} from '../../context/OptionsContext';

function StepsOptions() {
   const { steps } = useGetOptions();
   const dispatch = useGetOptionsDispatch();

   const handleStepChange = (e: SelectChangeEvent) => {
      dispatch({
         type: 'update-steps',
         steps: parseInt(e.target.value, 10),
      });
   };

   const stepOptions = new Array(60).fill(1);

   return (
      <ListItemButton color="secondary">
         <ListItemIcon>
            <StairsIcon />
         </ListItemIcon>
         <FormControl fullWidth>
            <InputLabel id="steps-label">Steps</InputLabel>
            <Select
               labelId="steps-label"
               id="steps-select"
               value={steps.toString()}
               label="Steps"
               onChange={handleStepChange}
            >
               {stepOptions.map((_, index) => {
                  return (
                     <MenuItem
                        value={`${index + 1}`}
                        id={`${index + 1}`}
                        key={index + 1}
                     >
                        {index + 1}
                     </MenuItem>
                  );
               })}
            </Select>
         </FormControl>
      </ListItemButton>
   );
}
export default StepsOptions;
