export enum Shapes {
   CROSS = 'cross',
   SQUARE = 'square',
   TRIANGLE = 'triangle',
   SPIRAL = 'spiral',
   ROTATE = 'rotate',
   DIAGONAL = 'diagonal',
   FULL_DIAGONAL = 'full-diagonal',
   FULL = 'full',
   FULL_1 = 'full one',
   FULL_2 = 'full two',
   FULL_3 = 'full three',
   TETRIS_GREEN_PIECE_RIGHT_TURN = 'tetris right turn (green piece)'
}

export enum RenderMode {
   COLORS = 'Colors',
   NUMBERS = 'Numbers'
};