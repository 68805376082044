import BookIcon from '@mui/icons-material/Book';
import { ListItemButton, ListItemIcon, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useGetOptions, useGetOptionsDispatch } from '../../context/OptionsContext';
import { RenderMode } from '../../types/baseEnums';
import { getRenderMode } from '../../services/GridService';

export const ColorByNumberOptions = () => {

    const { renderMode } = useGetOptions();
    const dispatch = useGetOptionsDispatch();

    const handleColorByNumberChange = (e: SelectChangeEvent) => {
        dispatch({
            type: 'update-render-mode',
            renderMode: e.target.value as RenderMode
        })
    }

    return (
        <ListItemButton color="secondary">
            <ListItemIcon>
                <BookIcon />
            </ListItemIcon>
            <FormControl fullWidth>
                <InputLabel id="render-mode">Render Mode</InputLabel>
                <Select
                    labelId="render-mode"
                    id="render-mode-select"
                    value={renderMode}
                    label="Cell Size"
                    onChange={handleColorByNumberChange}
                >
                    {getRenderMode().map((renderModeValue: string) => {
                        return (
                            <MenuItem
                                value={renderModeValue}
                                id={renderModeValue}
                                key={renderModeValue}
                            >
                                {renderModeValue}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>

        </ListItemButton>
    );
};