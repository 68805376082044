import { FALLBACK_ERROR_STRING, PARSE_RADIX } from '../constants';
import { RenderMode } from '../types/baseEnums';
import { Cell, CellClick, Row } from '../types/baseTypes';

type GridIndex = {
   indexData: number[];
};

// todo add export type
export function setupGrid(
   innerHeight: number,
   innerWidth: number,
   targetTop: number,
   targetLeft: number,
   squareSize: number,
   existingGrid?: Row[],
): Row[] {
   const howManySquaresY =
      Math.floor((innerHeight - targetTop) / squareSize) - 1;
   const howManySquaresX =
      Math.floor((innerWidth - targetLeft) / squareSize) - 1;

   let row = howManySquaresY * squareSize,
      col = howManySquaresX * squareSize;

   let grid: Row[] = new Array<Row>();
   let id = 0;
   let rowIndex = 0;
   let colIndex = 0;
   for (let i = 0; i < row; i += squareSize) {
      let temp = [];
      colIndex = 0;
      for (let j = 0; j < col; j += squareSize) {
         const tempCellColorIndex =
            existingGrid &&
               existingGrid.length > 0 &&
               rowIndex < existingGrid.length &&
               colIndex < existingGrid[rowIndex].cells.length
               ? existingGrid[rowIndex].cells[colIndex].colorIndex
               : 0;
         temp.push({
            id,
            row: rowIndex,
            column: colIndex,
            colorIndex: tempCellColorIndex,
            width: squareSize,
            height: squareSize,
         } as Cell);
         id++;
         colIndex++;
      }
      grid.push({ cells: temp });
      rowIndex++;
   }
   return grid;
}

export const getGridIndexData = (
   event: React.MouseEvent<HTMLElement>,
   dataAttributes: string[],
): GridIndex => {
   let indexData: number[] = [];
   dataAttributes.forEach((attribute: string) => {
      const index =
         event.currentTarget.getAttribute(attribute) ?? FALLBACK_ERROR_STRING;
      indexData.push(parseInt(index, PARSE_RADIX));
   });
   return {
      indexData,
   };
};

export const hasLineFrom = (lastClick: CellClick, currCellIndexData: number[]): boolean => {


   return true;
}

export const getRenderMode = () => {
   return Object.values(RenderMode);
}
