import { Shapes } from '../../types/baseEnums';
import type { Cell, Color, Row } from '../../types/baseTypes';

type PotentiallyFilledCell = Cell | undefined;
type CellUpdateDirections = PotentiallyFilledCell[];

export function getNextStepForGrid(
   grid: Row[],
   shape: Shapes,
   colors: Color[],
   row: number,
   column: number,
   stepNumber: number,
): Row[] {
   let tempGrid = [...grid];

   let top: PotentiallyFilledCell,
      bottom: PotentiallyFilledCell,
      left: PotentiallyFilledCell,
      right: PotentiallyFilledCell;
   let diagonalLeftTop: PotentiallyFilledCell,
      diagonalRightTop: PotentiallyFilledCell,
      diagonalLeftBottom: PotentiallyFilledCell,
      diagonalRightBottom: PotentiallyFilledCell;

   /*
        describe like this
        directionstToCheck = [
            [0, 1], [0, 2], [0, 3],
            [1, 0], 
            [2, 3]
        ]
    */

   if (column - 1 - stepNumber >= 0) {
      left = tempGrid[row].cells[column - 1 - stepNumber];
   }

   if (column + 1 + stepNumber < grid[row].cells.length) {
      right = tempGrid[row].cells[column + 1 + stepNumber];
   }

   if (row - 1 - stepNumber >= 0) {
      top = tempGrid[row - 1 - stepNumber].cells[column];
   }

   if (row + 1 + stepNumber < grid.length) {
      bottom = tempGrid[row + 1 + stepNumber].cells[column];
   }

   if (row - 1 - stepNumber >= 0 && column - 1 - stepNumber >= 0) {
      diagonalLeftTop =
         tempGrid[row - 1 - stepNumber].cells[column - 1 - stepNumber];
   }

   if (
      row - 1 - stepNumber >= 0 &&
      column + 1 + stepNumber < grid[row].cells.length
   ) {
      diagonalRightTop =
         tempGrid[row - 1 - stepNumber].cells[column + 1 + stepNumber];
   }

   if (row + 1 + stepNumber < grid.length && column - 1 - stepNumber >= 0) {
      diagonalLeftBottom =
         tempGrid[row + 1 + stepNumber].cells[column - 1 - stepNumber];
   }

   if (
      row + 1 + stepNumber < grid.length &&
      column + 1 + stepNumber < grid[row].cells.length
   ) {
      diagonalRightBottom =
         tempGrid[row + 1 + stepNumber].cells[column + 1 + stepNumber];
   }

   let directions: CellUpdateDirections = [];
   switch (shape) {
      case Shapes.CROSS:
         directions = [left, right, top, bottom];
         break;
      case Shapes.SQUARE:
         directions = [
            left,
            right,
            top,
            bottom,
            diagonalLeftBottom,
            diagonalLeftTop,
            diagonalRightBottom,
            diagonalRightTop,
         ];
         break;
      case Shapes.TRIANGLE:
         directions = [top, diagonalLeftBottom, diagonalRightBottom];
         break;
      case Shapes.SPIRAL:
         directions =
            stepNumber % 4 === 0
               ? [top, diagonalLeftBottom, diagonalRightBottom]
               : stepNumber % 4 === 1
                  ? [right, diagonalLeftBottom, diagonalLeftTop]
                  : stepNumber % 4 === 2
                     ? [left, diagonalRightBottom, diagonalRightTop]
                     : [bottom, diagonalLeftTop, diagonalRightTop];
         break;
      case Shapes.ROTATE:
         directions =
            stepNumber % 2 === 0
               ? [left, right, top, bottom]
               : [
                  diagonalLeftBottom,
                  diagonalLeftTop,
                  diagonalRightBottom,
                  diagonalRightTop,
               ];
         break;
      case Shapes.DIAGONAL:
         directions =
            stepNumber % 2 === 0
               ? [diagonalLeftBottom, diagonalRightTop]
               : [diagonalLeftTop, diagonalRightBottom];
         break;
      case Shapes.FULL_DIAGONAL:
         directions = [
            diagonalLeftBottom,
            diagonalLeftTop,
            diagonalRightBottom,
            diagonalRightTop,
         ];
         break;
      case Shapes.FULL:
         directions = getFullDirections(tempGrid, [
            diagonalLeftTop,
            diagonalRightTop,
            diagonalRightBottom,
            diagonalLeftBottom,
         ]);
         break;
      case Shapes.FULL_1:
         directions = getFullDirections(tempGrid, [
            diagonalLeftTop,
            diagonalRightTop,
         ]);
         break;
      case Shapes.FULL_2:
         directions = getFullDirections(tempGrid, [undefined,
            diagonalRightTop,
            diagonalRightBottom,
         ]);
         break;
      case Shapes.FULL_3:
         directions = getFullDirections(tempGrid, [
            top,
            right,
            left,
            bottom,
         ]);
         break;

      case Shapes.TETRIS_GREEN_PIECE_RIGHT_TURN:
         directions = [
            left, top, diagonalRightTop
         ];
         break;
      default:
         directions = [left, right, top, bottom];
         break;
   }
   //console.log(directions);

   directions.forEach((cell: PotentiallyFilledCell) => {
      if (cell) {
         cell.colorIndex =
            cell.colorIndex + 1 >= colors.length ? 0 : cell.colorIndex + 1;
      }
   });

   return tempGrid;
}

export function getShapes(): string[] {
   return Object.values(Shapes);
}

export function getDefaultShape(): string {
   return Shapes.CROSS;
}

// helper functions

const getFullDirections = (
   grid: Row[],
   directionsToGo: CellUpdateDirections,
) => {
   let finalDirections: CellUpdateDirections = [];
   let row: number, col: number;

   if (directionsToGo[0] !== undefined && directionsToGo[1]) {
      row = directionsToGo[0].row;
      for (
         let i = directionsToGo[0].column;
         i <= directionsToGo[1].column;
         i++
      ) {
         finalDirections.push(grid[row].cells[i]);
      }
   }

   // for (let i = directionsToGo[0].column + 1; i < directionsToGo[1].column; i++) { // this pattern leaves out the diagonal which looks cool
   //     directions.push(temp[row][i]);
   // }

   if (
      directionsToGo[1] !== undefined &&
      directionsToGo[1].column !== undefined &&
      directionsToGo[2]
   ) {
      col = directionsToGo[1].column;
      for (let i = directionsToGo[1].row + 1; i <= directionsToGo[2].row; i++) {
         finalDirections.push(grid[i].cells[col]);
      }
   }

   if (
      directionsToGo[2] !== undefined &&
      directionsToGo[2].row !== undefined &&
      directionsToGo[3]
   ) {
      row = directionsToGo[2].row;
      for (
         let i = directionsToGo[2].column - 1;
         i >= directionsToGo[3].column;
         i--
      ) {
         finalDirections.push(grid[row].cells[i]);
      }
   }

   if (
      directionsToGo[3] !== undefined &&
      directionsToGo[3].column !== undefined &&
      directionsToGo[1]
   ) {
      col = directionsToGo[3].column;
      for (let i = directionsToGo[3].row - 1; i > directionsToGo[1].row; i--) {
         finalDirections.push(grid[i].cells[col]);
      }
   }
   return finalDirections;
};
