import { createContext, useContext, useReducer } from 'react';
import { RenderMode, Shapes } from '../types/baseEnums';
import { getDefaultGridTheme, getThemeById } from '../services/ThemeService';
import { ColorTheme } from '../types/baseTypes';


interface OptionsState {
   shape: Shapes;
   steps: number;
   theme: ColorTheme;
   squareSize: number;
   renderMode: RenderMode;
}

type OptionsUpdateShapeAction = { type: 'update-shape'; shape: Shapes };
type OptionsUpdateStepsAction = { type: 'update-steps'; steps: number };
type OptionsUpdateThemeAction = { type: 'update-theme'; themeId: string };
type OptionsUpdateSquareSizeAction = { type: 'update-square-size'; squareSize: number };
type OptionsUpdateRenderModeAction = { type: 'update-render-mode'; renderMode: RenderMode };

export type OptionsActions =
   | OptionsUpdateShapeAction
   | OptionsUpdateStepsAction
   | OptionsUpdateThemeAction
   | OptionsUpdateSquareSizeAction
   | OptionsUpdateRenderModeAction;

const initialOptions: OptionsState = {
   shape: Shapes.CROSS,
   steps: 60,
   theme: getDefaultGridTheme(),
   squareSize: 25,
   renderMode: RenderMode.COLORS
};

export const OptionsContext = createContext<OptionsState>(initialOptions);
export const OptionsContextDispatch = createContext<
   React.Dispatch<OptionsActions>
>(() => { });

export function OptionsProvider({
   children,
}: {
   children: React.ReactElement;
}) {
   const [options, dispatch] = useReducer(reducer, initialOptions);

   return (
      <OptionsContext.Provider value={options}>
         <OptionsContextDispatch.Provider value={dispatch}>
            {children}
         </OptionsContextDispatch.Provider>
      </OptionsContext.Provider>
   );
}

export function useGetOptions() {
   return useContext(OptionsContext);
}

export function useGetOptionsDispatch() {
   return useContext(OptionsContextDispatch);
}

function reducer(state: OptionsState, action: OptionsActions): OptionsState {
   switch (action.type) {
      case 'update-shape':
         return {
            ...state,
            shape: action.shape,
         };
      case 'update-steps':
         return {
            ...state,
            steps: action.steps,
         };
      case 'update-theme':
         return {
            ...state,
            theme: getThemeById(parseInt(action.themeId, 10)),
         };
      case 'update-square-size':
         return {
            ...state,
            squareSize: action.squareSize,
         }
      case 'update-render-mode':
         return {
            ...state,
            renderMode: action.renderMode,
         }
      default:
         return {
            ...state,
         };
   }
}
