import { createContext, useContext, useReducer } from 'react';
import { CellClick } from '../types/baseTypes';

export type SavedClickPattern = {
   clicks: CellClick[];
   name: string;
};

interface ClickState {
   clicks: CellClick[];
   savedClickPatterns: SavedClickPattern[];
   playClicks: {
      timestamp: number;
      clicks: CellClick[];
      autoplay: boolean;
   };
}

type ClicksUpdateAction = { type: 'update-clicks'; click: CellClick };
type SaveClicksAction = { type: 'save-clicks'; clicks: CellClick[] };
type PlayClicksAction = { type: 'play-clicks'; clicks: CellClick[], autoplay: boolean };

export type ClicksActions =
   | ClicksUpdateAction
   | SaveClicksAction
   | PlayClicksAction;

const initialClicksState: ClickState = {
   clicks: [],
   savedClickPatterns: [],
   playClicks: {
      timestamp: new Date().getMilliseconds(),
      clicks: [],
      autoplay: false,
   },
};

export const ClicksContext = createContext<ClickState>(initialClicksState);
export const ClicksContextDispatch = createContext<
   React.Dispatch<ClicksActions>
>(() => { });

export function ClicksProvider({ children }: { children: React.ReactElement }) {
   const [clicksState, dispatch] = useReducer(
      clicksReducer,
      initialClicksState,
   );

   return (
      <ClicksContext.Provider value={clicksState}>
         <ClicksContextDispatch.Provider value={dispatch}>
            {children}
         </ClicksContextDispatch.Provider>
      </ClicksContext.Provider>
   );
}

export function useGetClicks() {
   return useContext(ClicksContext);
}

export function useGetClicksDispatch() {
   return useContext(ClicksContextDispatch);
}

function clicksReducer(state: ClickState, action: ClicksActions): ClickState {
   switch (action.type) {
      case 'update-clicks':
         return {
            ...state,
            clicks: [...state.clicks, action.click],
         };
      case 'save-clicks':
         return {
            ...state,
            savedClickPatterns: [
               ...state.savedClickPatterns,
               {
                  clicks: action.clicks,
                  name: `Pattern ${state.savedClickPatterns.length + 1}`,
               },
            ],
            clicks: [],
         };
      case 'play-clicks':
         return {
            ...state,
            playClicks: {
               clicks: action.clicks,
               autoplay: action.autoplay,
               timestamp: new Date().getMilliseconds(),
            },
         };
      default:
         return initialClicksState;
   }
}
