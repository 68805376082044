import * as React from 'react';
import ShapesOptions from '../GridOptions/ShapesOptions';
import StepsOptions from '../GridOptions/StepsOptions';
import ClickPatterns from './ClickPatterns/ClickPatterns';
import { ThemeOptions } from '../GridOptions/ThemeOptions';
import { WaveOptions } from '../GridOptions/WaveOptions';
import { SpeedOptions } from '../GridOptions/SpeedOptions';
import { SquareSizeOptions } from '../GridOptions/SquareSizeOptions';
import { ColorByNumberOptions } from '../GridOptions/ColorByNumberOptions';

export const mainDashboardMenuItems = (
   <React.Fragment>
      <ShapesOptions />
      <StepsOptions />
      <ThemeOptions />
      <SquareSizeOptions />
      <ColorByNumberOptions />
      <WaveOptions />
      <SpeedOptions />
   </React.Fragment>
);

export const secondaryDashboardMenuItems = (
   <React.Fragment>
      <ClickPatterns />
   </React.Fragment>
);
