import type { ColorTheme } from '../types/baseTypes';

export const THEMES: ColorTheme[] = [
   {
      id: 0,
      name: 'Neon-Rainbow',
      colors: [
         { color: '#8c00fc' },
         { color: '#3500ff' },
         { color: '#01fe01' },
         { color: '#fffe37' },
         { color: '#ff8600' },
         { color: '#ed0003' },
         { color: '#ff8600' },
         { color: '#fffe37' },
         { color: '#01fe01' },
         { color: '#3500ff' },
      ],
   },
   {
      id: 1,
      name: 'Popular',
      colors: [
         { color: 'rgb(34, 40, 49)' },
         { color: 'rgb(57, 62, 70)' },
         { color: 'rgb(0, 173, 181)' },
         { color: 'rgb(238, 238, 238)' },
         { color: 'rgb(168, 230, 207)' },
         { color: 'rgb(220, 237, 193)' },
         { color: 'rgb(255, 211, 182)' },
         { color: 'rgb(255, 170, 165)' },
         { color: '#B983FF' },
         { color: '#94B3FD' },
         { color: '#94DAFF' },
         { color: '#99FEFF' },
      ],
   },
   {
      id: 2,
      name: 'Long-Rainbow',
      colors: [
         { color: 'red' },
         { color: 'orange' },
         { color: 'yellow' },
         { color: 'green' },
         { color: 'blue' },
         { color: 'indigo' },
         { color: 'violet' },
         { color: 'indigo' },
         { color: 'blue' },
         { color: 'green' },
         { color: 'yellow' },
         { color: 'orange' },
      ],
   },
   {
      id: 3,
      name: 'Rose',
      colors: [
         { color: '#f72585ff' },
         { color: '#b5179eff' },
         { color: '#7209b7ff' },
         { color: '#560badff' },
         { color: '#480ca8ff' },
         { color: '#3a0ca3ff' },
         { color: '#3f37c9ff' },
         { color: '#4361eeff' },
         { color: '#4895efff' },
         { color: '#4cc9f0ff' },
      ],
   },
   {
      id: 4,
      name: 'Bitmap',
      colors: [
         { color: 'rgb(0, 0, 0)' },
         { color: 'rgb(17, 17, 17)' },
         { color: 'rgb(34, 34, 34)' },
         { color: 'rgb(51, 51, 51)' },
         { color: 'rgb(78, 78, 78)' },
         { color: 'rgb(95, 95, 95)' },
      ]
   },
   {
      id: 5,
      name: 'Spectral',
      colors: [
         { color: '#d53e4f' },
         { color: '#f46d43' },
         { color: '#fdae61' },
         { color: '#fee08b' },
         { color: '#e6f598' },
         { color: '#abdda4' },
         { color: '#66c2a5' },
         { color: '#3288bd' }
      ]
   },
   {
      id: 6,
      name: 'Pastel',
      colors: [
         { color: '#FBF8CC' },
         { color: '#FDE4CF' },
         { color: '#FFCFD2' },
         { color: '#F1C0E8' },
         { color: '#CFBAB0' },
         { color: '#A3C4F3' },
         { color: '#90DBF4' },
         { color: '#8EECF5' },
         { color: '#98F5E1' },
         { color: '#B9FBC0' }
      ]
   },
   {
      id: 7,
      name: 'eBay',
      colors: [
         { color: '#d74946' },
         { color: '#0069ce' },
         { color: '#f0af31' },
         { color: '#94b224' }
      ]
   },
   {
      id: 8,
      name: 'cyan',
      colors: [
         { color: '#00fff5' },
         { color: '#aafbfb' },
         { color: '#4cd4cc' },
         { color: '#049bba' },
         { color: '#10808b' }
      ]
   },
   {
      id: 9,
      name: 'electric-indigo',
      colors: [
         { color: '#2d00f7ff' },
         { color: '#6a00f4ff' },
         { color: '#8900f2ff' },
         { color: '#a100f2ff' },
         { color: '#b100e8ff' },
         { color: '#bc00ddff' },
         { color: '#d100d1ff' },
         { color: '#db00b6ff' },
         { color: '#e500a4ff' },
         { color: '#f20089ff' }
      ]
   },
   {
      id: 10,
      name: 'black-tint-bitmap',
      colors: [
         { color: '#000000' },
         { color: '#191919' },
         { color: '#323232' },
         { color: '#4c4c4c' },
         { color: '#666666' },
         { color: '#7f7f7f' },
         { color: '#999999' },
         { color: '#b2b2b2' },
         { color: '#cccccc' },
         { color: '#e5e5e5' },
         { color: '#ffffff' }
      ]
   },
   {
      id: 11,
      name: 'expanded-rainbow',
      colors: [
         { color: "#FF0000" }, // Red
         { color: "#FF4000" }, // Transition from Red to Orange
         { color: "#FF8000" },
         { color: "#FFBF00" },
         { color: "#FFFF00" }, // Orange
         { color: "#D2FF00" }, // Transition from Orange to Yellow
         { color: "#A6FF00" },
         { color: "#7AFF00" },
         { color: "#4EFF00" }, // Yellow
         { color: "#1CFF00" }, // Transition from Yellow to Green
         { color: "#00FF19" },
         { color: "#00FF55" },
         { color: "#00FF8C" }, // Green
         { color: "#00FFC3" }, // Transition from Green to Blue
         { color: "#00FFFF" },
         { color: "#00C3FF" },
         { color: "#008CFF" }, // Blue
         { color: "#0055FF" }, // Transition from Blue to Indigo
         { color: "#0019FF" },
         { color: "#1C00FF" },
         { color: "#4E00FF" }, // Indigo
         { color: "#7A00FF" }, // Transition from Indigo to Violet
         { color: "#A600FF" },
         { color: "#D200FF" },
         { color: "#FF00FF" }, // Violet
      ]
   },
   {
      id: 12,
      name: '90s pastel',
      colors: [
         { color: "#FFB3BA" }, // Soft Pink (Pastel)
         { color: "#FFDFBA" }, // Peach (Pastel)
         { color: "#FFFFBA" }, // Light Yellow (Pastel)
         { color: "#BAFFC9" }, // Mint Green (Pastel)
         { color: "#BAE1FF" }, // Sky Blue (Pastel)
         { color: "#B5B9FF" }, // Periwinkle (90s influence)
         { color: "#ECCAFF" }, // Lavender (Pastel)
         { color: "#FFC3A0" }, // Apricot (Pastel)
         { color: "#FF90B3" }, // Bubblegum Pink (90s influence)
         { color: "#FF7EE5" }, // Soft Magenta (90s influence)
         { color: "#FF61A6" }, // Pinkish (90s influence)
         { color: "#FF61CF" }, // Light Fuchsia (90s influence)
         { color: "#FF4BCE" }, // Neon Pink (90s influence)
         { color: "#FF6FCF" }, // Soft Neon Pink (Blend)
         { color: "#FFA07A" }, // Light Coral (Pastel)
         { color: "#FFD700" }, // Bright Yellow (90s influence)
         { color: "#7FFF00" }, // Chartreuse (90s influence)
         { color: "#00FF7F" }, // Spring Green (90s influence)
         { color: "#7FFFD4" }, // Aquamarine (90s influence)
         { color: "#B0E0E6" }  // Powder Blue (Pastel)
      ]
   },
   {
      id: 13,
      name: 'green world',
      colors: [
         { color: "#E9F5DB" }, // Very Light Green
         { color: "#D4EAD5" }, // Soft Mint
         { color: "#BFE5B3" }, // Light Mint
         { color: "#A9D99B" }, // Pale Lime
         { color: "#93CE83" }, // Soft Lime
         { color: "#7DC274" }, // Bright Lime
         { color: "#67B765" }, // Grass Green
         { color: "#51AB56" }, // Medium Green
         { color: "#3BA048" }, // Leaf Green
         { color: "#25953A" }, // Classic Green
         { color: "#0F8A2C" }, // Rich Green
         { color: "#007F21" }, // Dark Green
         { color: "#006F1C" }, // Deep Green
         { color: "#005E17" }, // Forest Green
         { color: "#004D12" }, // Pine Green
         { color: "#003C0D" }, // Hunter Green
         { color: "#002B08" }, // Dark Forest Green
         { color: "#001A03" }, // Very Dark Green
         { color: "#033D21" }, // Dark Emerald
         { color: "#045D37" }  // Emerald Green
      ]
   }
];

export const getThemeById = (themeId: number): ColorTheme => {
   return THEMES[themeId];
};

export const getDefaultGridTheme = (): ColorTheme => {
   return getThemeByName(DEFAULT_THEME_NAME);
};

export const getThemeByName = (themeName: string): ColorTheme => {
   return THEMES.find((theme) => theme.name === themeName) || THEMES[0];
};

export const DEFAULT_THEME_NAME = 'green-world';
