import {
   FormControl,
   InputLabel,
   ListItemButton,
   ListItemIcon,
   MenuItem,
   Select,
   SelectChangeEvent,
} from '@mui/material';
import PaletteIcon from '@mui/icons-material/Palette';
import {
   useGetOptions,
   useGetOptionsDispatch,
} from '../../context/OptionsContext';
import { THEMES } from '../../services/ThemeService';
import { Color, ColorTheme } from '../../types/baseTypes';

export const ThemeOptions = () => {
   const { theme } = useGetOptions();
   const dispatch = useGetOptionsDispatch();

   const handleThemeChange = (e: SelectChangeEvent) => {
      dispatch({
         type: 'update-theme',
         themeId: e.target.value,
      });
   };

   return (
      <ListItemButton>
         <ListItemIcon>
            <PaletteIcon />
         </ListItemIcon>
         <FormControl fullWidth>
            <InputLabel id="themes-label">Themes</InputLabel>
            <Select
               labelId="themes-label"
               id="themes-select"
               value={theme.id.toString()}
               label="Themes"
               onChange={handleThemeChange}
            >
               {THEMES.map((theme: ColorTheme) => (
                  <MenuItem value={theme.id} key={theme.id}>
                     {theme.name}
                     &nbsp;&nbsp;
                     {theme.colors.map((colorDAO: Color) => {
                        return (
                           <span style={{
                              width: '10px',
                              height: '10px',
                              backgroundColor: colorDAO.color
                           }} />
                        );
                     })}
                  </MenuItem>
               ))}
            </Select>
         </FormControl>
      </ListItemButton>
   );
};
