import './App.css';
import GridCreatePage from './pages/GridCreatePage';
import { ThemeProvider } from '@mui/material';
import { materialUITheme } from './materialUITheme';
// import MineSweeperPage from './pages/MinesweeperPage';
// import FintronPage from './pages/FintronPage';
// import BankPage from './pages/BankPage';

function App() {
   return (
      <ThemeProvider theme={materialUITheme}>
         <GridCreatePage />
         {/* <MineSweeperPage /> */}
         {/* <BankPage /> */}
      </ThemeProvider>
   );
}

export default App;
