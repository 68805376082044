import { useCallback, useEffect, useRef, useState } from 'react';
import { getGridIndexData, setupGrid } from '../services/GridService';
import { Cell, Grid, Row } from '../types/baseTypes';
import { getDefaultGridTheme } from '../services/ThemeService';
import { getNextStepForGrid } from '../services/shapes/ShapeService';
import './GeometricGrid.css';
import { useGetOptions } from '../context/OptionsContext';
import { useGetClicks, useGetClicksDispatch } from '../context/ClicksContext';
import { RenderMode, Shapes } from '../types/baseEnums';

function GeometricGrid(props: { open: boolean }) {
   const { open } = props;
   const [grid, setGrid] = useState<Grid>({
      rows: [],
      theme: getDefaultGridTheme(),
   });
   const gridRef = useRef<HTMLDivElement>(null);
   const { shape, squareSize, steps, theme, renderMode } = useGetOptions();
   const { clicks, playClicks } = useGetClicks();
   const dispatch = useGetClicksDispatch();

   const getInitialGridRows = useCallback((rows?: Row[]) => {
      try {
         const rect = gridRef.current?.getBoundingClientRect();
         if (rect) {
            // figure out why grid.rows not being passed in, has to do with callback probably, its not getting newest grid version
            const gridRows = [
               ...setupGrid(
                  window.innerHeight,
                  window.innerWidth,
                  rect.top,
                  rect.left,
                  squareSize,
                  rows ? rows : undefined,
               ),
            ];
            setGrid((oldGrid) => {
               return {
                  ...oldGrid,
                  rows: gridRows,
               };
            });
         }
      } catch (e) {
         console.log('uh oh some error ', e);
      }
   }, [squareSize]);

   // these are all resizing catches
   useEffect(() => {
      console.log('in da timer');
      setTimeout(() => {
         getInitialGridRows(grid.rows);
      }, 1000);
      // eslint-disable-next-line
   }, [open, getInitialGridRows]);

   // problem is this function is cached and not returning latest values
   useEffect(() => {
      window.addEventListener('resize', () => {
         getInitialGridRows();
      });
      return () => {
         window.removeEventListener('resize', () => {
            getInitialGridRows();
         });
      };
   }, [getInitialGridRows]);

   useEffect(() => {
      getInitialGridRows();
   }, [getInitialGridRows]);

   useEffect(() => {
      let intervalId: NodeJS.Timer;
      if (playClicks.clicks.length > 0) {
         if (playClicks.autoplay) {
            intervalId = setInterval(() => {
               for (let i = 0; i < playClicks.clicks.length; i++) {
                  const click = playClicks.clicks[i];
                  updateGrid(click.rowIndex, click.columnIndex, shape, steps);
               }
            }, 500);
         } else {
            for (let i = 0; i < playClicks.clicks.length; i++) {
               const click = playClicks.clicks[i];
               updateGrid(click.rowIndex, click.columnIndex, shape, steps);
            }
         }

      }
      return () => {
         if (intervalId) {
            window.clearInterval(intervalId);
         }

      }
      // eslint-disable-next-line
   }, [playClicks]);

   const handleCellClick = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (e.currentTarget) {
         const { indexData } = getGridIndexData(e, ['data-row', 'data-column']);
         const [rowIndex, columnIndex] = indexData;
         if (rowIndex < 0 || columnIndex < 0) {
            throw new Error(
               'Couldnt parse the row or column, that shouldnt happen',
            );
         }
         dispatch({
            type: 'update-clicks',
            click: {
               rowIndex,
               columnIndex,
               shape,
               step: steps,
            },
         });
         updateGrid(rowIndex, columnIndex, shape, steps);
      }
   };

   // todo: spray can idea can use this
   // const handleMouseOver = (e: React.MouseEvent<HTMLDivElement>) => {
   //    const { indexData } = getGridIndexData(e, ['data-row', 'data-column']);
   //    let lastClick = clicks[clicks.length - 1];

   //    if (hasLineFrom(lastClick, indexData)) {
   //       e.currentTarget.className = 'cell hover';
   //       highlightLineFrom(lastClick, indexData);
   //    }
   // }

   const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
      const { indexData } = getGridIndexData(e, ['data-row', 'data-column']);
      let lastClick = clicks[clicks.length - 1];

      clearHoverList();

      // const x1 =
      //    lastClick.rowIndex < indexData[0] ? lastClick.rowIndex : indexData[0];
      // const x2 =
      //    lastClick.rowIndex > indexData[0] ? lastClick.rowIndex : indexData[0];

      // const y1 =
      //    lastClick.columnIndex < indexData[0]
      //       ? lastClick.columnIndex
      //       : indexData[1];
      // const y2 =
      //    lastClick.columnIndex > indexData[0]
      //       ? lastClick.columnIndex
      //       : indexData[1];

      // const deltaX = Math.abs(x2 - x1);
      // const deltaY = Math.abs(y2 - y1);

      // if (deltaX === deltaY) {
      //    for (let i = 0; i < deltaX; i++) {
      //       updateGrid(x1 + i, y1 + i, shape, steps);
      //    }
      //    return;
      // }
      // if (deltaX > 0 && deltaY === 0) {
      //    for (let i = 0; i < deltaX; i++) {
      //       updateGrid(x1 + i, y1, shape, steps);
      //    }
      //    return;
      // }
      // if (deltaX === 0 && deltaY > 0) {
      //    for (let i = 0; i < deltaY; i++) {
      //       updateGrid(x1, y1 + i, shape, steps);
      //    }
      // }

      function highlightPath(start: any, end: any) {
         const queue = [start];
         const visited = new Map();
         visited.set(`${start.row},${start.col}`, null);

         while (queue.length > 0) {
            const { row, col } = queue.shift();

            if (row === end.row && col === end.col) {
               console.log(visited);
               return reconstructPath(start, end, visited);
            }

            for (const [dr, dc] of [[1, 0], [-1, 0], [0, 1], [0, -1], [1, 1], [1, -1], [-1, 1], [-1, -1]]) {
               const newRow = row + dr;
               const newCol = col + dc;

               if (newRow >= 0 && newRow < grid.rows.length && newCol >= 0 && newCol < grid.rows[0].cells.length && !visited.has(`${newRow},${newCol}`)) {
                  queue.push({ row: newRow, col: newCol });
                  visited.set(`${newRow},${newCol}`, { row, col });
               }
            }
         }
      }

      function reconstructPath(start: any, end: any, visited: any) {
         let current = end;
         while (current) {
            //highlightCell(current.row, current.col, true);
            console.log(current.row, current.col)
            if (current !== end && current !== start) {
               dispatch({
                  type: 'update-clicks',
                  click: {
                     rowIndex: current.row,
                     columnIndex: current.col,
                     shape,
                     step: steps,
                  },
               });
               updateGrid(current.row, current.col, shape, steps);
            }
            current = visited.get(`${current.row},${current.col}`);
         }
      }

      highlightPath({ row: indexData[0], col: indexData[1] }, { row: lastClick.rowIndex, col: lastClick.columnIndex });


   };

   const updateGrid = (
      rowIndex: number,
      columnIndex: number,
      shape: Shapes,
      steps: number,
   ) => {
      let updatedGridRows: Row[],
         stepNumber = 0;
      // we can achieve different patterned effects by increment or decrementing stepNumber
      updatedGridRows = getNextStepForGrid(
         grid.rows,
         shape,
         theme.colors,
         rowIndex,
         columnIndex,
         stepNumber,
      );
      setGrid((oldGrid): Grid => {
         return {
            ...oldGrid,
            rows: updatedGridRows,
         };
      });
      if (true) {
         const interval = setInterval(() => {
            if (stepNumber === steps) {
               window.clearInterval(interval);
            }
            updatedGridRows = getNextStepForGrid(
               grid.rows,
               shape,
               theme.colors,
               rowIndex,
               columnIndex,
               stepNumber,
            );
            setGrid((oldGrid: Grid) => {
               return {
                  ...oldGrid,
                  rows: updatedGridRows,
               };
            });
            stepNumber++;
         }, 1);
         //setIntervals(prevIntervals => [...prevIntervals, interval]); // this is for reseting the grid, do we really need this though??
      }
   };

   const clearHoverList = () => {
      let matches = document.querySelectorAll('.cell.hover');
      matches.forEach((match) => {
         match.classList.remove('cell');
      })
   }

   // const highlightLineFrom = (lastClick: CellClick, currCellIndexData: number[]) => {

   // }

   return (
      <div className="grid" ref={gridRef}>
         {grid.rows.map((row: Row, index: number) => {
            let rowStyle = {
               height: squareSize,
            };
            return (
               <div key={index} className="row" style={rowStyle}>
                  {row.cells.map((cell: Cell, columnIndex: number) => {
                     let style = {
                        width: cell.width,
                        height: cell.height,
                        background:
                           cell.colorIndex < theme.colors.length
                              ? theme.colors[cell.colorIndex].color
                              : theme.colors[0].color,
                        border: '1px solid black',
                        display: 'inline-block',
                        textAlign: 'center',
                        '&:hover': {
                           backgroundColor: 'blue',
                        }
                     };
                     style = renderMode === RenderMode.NUMBERS ?
                        {
                           ...style,
                           background: 'white'
                        } :
                        style
                     return (
                        <div
                           className="cell"
                           data-row={index}
                           data-column={columnIndex}
                           key={cell.id}
                           //onClick={handleCellClick}
                           onMouseDown={handleCellClick}
                           onMouseUp={handleMouseUp}
                           //onMouseOver={handleMouseOver}
                           // onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
                           style={style as any}
                        >
                           {renderMode === RenderMode.NUMBERS ?
                              cell.colorIndex
                              :
                              ""}
                        </div>
                     );
                  })}
               </div>
            );
         })}
      </div>
   );
}
export default GeometricGrid;
