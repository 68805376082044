import { ListItemButton, ListItemIcon, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import SelectAllIcon from '@mui/icons-material/SelectAll';
import { useGetOptions, useGetOptionsDispatch } from "../../context/OptionsContext";

export const SquareSizeOptions = () => {

    const { squareSize } = useGetOptions();
    const dispatch = useGetOptionsDispatch();

    const squareSizeOptions: number[] = [10, 15, 20, 25, 30];

    const handleSquareSizeChange = (e: SelectChangeEvent) => {
        dispatch({
            type: 'update-square-size',
            squareSize: parseInt(e.target.value, 10)
        });
    }

    return (
        <ListItemButton color="secondary">
            <ListItemIcon>
                <SelectAllIcon />
            </ListItemIcon>
            <FormControl fullWidth>
                <InputLabel id="square-size-label">Cell Size</InputLabel>
                <Select
                    labelId="square-size-label"
                    id="square-size-select"
                    value={squareSize.toString()}
                    label="Cell Size"
                    onChange={handleSquareSizeChange}
                >
                    {squareSizeOptions.map((squareSize, index) => {
                        return (
                            <MenuItem
                                value={squareSize.toString()}
                                id={squareSize.toString()}
                                key={squareSize}
                            >
                                {squareSize.toString()}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </ListItemButton>
    );
}