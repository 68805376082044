import { Button, Checkbox, FormControlLabel, ListSubheader, Stack } from '@mui/material';
import { CellClick } from '../../../types/baseTypes';
import {
   SavedClickPattern,
   useGetClicksDispatch,
} from '../../../context/ClicksContext';

interface ClickPatternDetailsProps {
   patterns: SavedClickPattern[];
}

export const ClickPatternDetails = (props: ClickPatternDetailsProps) => {
   const { patterns } = props;
   const dispatch = useGetClicksDispatch();

   const handlePatternClick = (pattern: CellClick[]) => {
      dispatch({
         type: 'play-clicks',
         clicks: pattern,
         autoplay: false
      });
   };

   const handleAutoplayChange = (e: React.ChangeEvent<HTMLInputElement>, patternClicks: CellClick[]) => {
      console.log(e.target.checked)
      dispatch({
         type: 'play-clicks',
         clicks: patternClicks,
         autoplay: e.target.checked
      });
   }

   return (
      <ListSubheader
         component="div"
         sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
         }}
      >
         {patterns.map((pattern: SavedClickPattern, index: number) => {
            return (
               <ListSubheader
                  key={pattern.name}
                  component="div"
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     marginTop: 2,
                  }}
               >
                  <Stack>
                     <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handlePatternClick(pattern.clicks)}
                     >
                        {/* {pattern.name} Clicks {pattern.clicks.length}{' '} */}
                        Clicks {pattern.clicks.length}{' '}
                     </Button>
                     <FormControlLabel sx={{ ml: 1 }} control={<Checkbox onChange={(e) => handleAutoplayChange(e, pattern.clicks)} />} label="Autoplay 500ms?" />
                  </Stack>

               </ListSubheader>
            );
         })}
      </ListSubheader>
   );
};
