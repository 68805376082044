import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';

export const SpeedOptions = () => {
   return (
      <ListItemButton>
         <ListItemIcon>
            <TwoWheelerIcon />
         </ListItemIcon>
         <ListItemText primary="Speed" />
      </ListItemButton>
   );
};
