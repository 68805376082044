import { createTheme } from '@mui/material';
import { lightBlue } from '@mui/material/colors';

export const materialUITheme = createTheme({
   palette: {
      primary: {
         main: lightBlue[700],
      },
   },
});
